import React, { useState, useEffect } from 'react';

import { Drawer, Divider, notification } from 'antd';

import { reqGamesInfo, deleteGame } from '../../api';

import './gamesManager.css';

import GameCard from './GameCard';
import GameEditorForm from './GameEditorForm';
import CardsManager from '../cardsManager/CardsManager';
import GameStats from './GameStats';

function GamesManager() {
  const [games, setGames] = useState([]);
  const [isGameEditorVisible, setIsGameEditorVisible] = useState(false);
  const [isGameCardsVisible, setIsGameCardsVisible] = useState(false);
  const [activeGame, setActiveGame] = useState(null);
  const [selectedGameId, setSelectedGameId] = useState(null);
  const [selectedGameIdStats, setSelectedGameIdStats] = useState(null);
  const [isGameStatsVisible, setIsGameStatsVisible] = useState(false);

  useEffect(() => {
    async function getGamesInfo() {
      const { data: games = [], status } = await reqGamesInfo();

      if (status === 200) {
        setGames(games);
      } else if (status === 401) {
        notification.warning({
          message: 'The authorization token is expired',
          description: 'Receive updated token and repeat the action.',
        });
      } else {
        notification.error({
          message: 'Server Error',
          description: 'Cannot process the operation.',
        });
      }
    }

    getGamesInfo();
  }, []);

  const editGame = (game) => {
    setActiveGame(game);
    setIsGameEditorVisible(true);
  };

  const closeGameEditor = () => {
    setIsGameEditorVisible(false);
    setActiveGame(null);
  };

  const closeGameStats = () => {
    setIsGameStatsVisible(false);
    setSelectedGameIdStats(null);
  };

  const showGameStats = (gameId) => {
    setIsGameStatsVisible(true);
    setSelectedGameIdStats(gameId);
  };

  async function removeGame(gameId) {
    const { status } = await deleteGame(gameId);

    if (status === 200) {
      const updateGames = games.filter((game) => game._id !== gameId);
      setGames(updateGames);
    } else if (status === 401) {
      notification.warning({
        message: 'The authorization token is expired',
        description: 'Receive updated token and repeat the action.',
      });
    } else {
      notification.error({
        message: 'Server Error',
        description: 'Cannot process the operation.',
      });
    }
  }

  const updateEditedGame = (updatedGame) => {
    const updatedGames = games.map((game) =>
      game._id === updatedGame._id ? updatedGame : game
    );

    setGames(updatedGames);
  };

  const showGameCards = (gameId) => {
    setIsGameCardsVisible(true);
    setSelectedGameId(gameId);
  };

  const closeGameCards = () => {
    setIsGameCardsVisible(false);
    setSelectedGameId(null);
  };

  return (
    <>
      <div className="cardsContainer">
        {games.length &&
          games.map((game) => (
            <div key={game._id} className="gameCard">
              <GameCard
                game={game}
                editGame={editGame}
                showGameCards={showGameCards}
                showGameStats={showGameStats}
                removeGame={removeGame}
              />
            </div>
          ))}
      </div>
      {activeGame && (
        <Drawer
          width={550}
          title={`Game Id: ${activeGame._id}`}
          placement="right"
          onClose={closeGameEditor}
          visible={isGameEditorVisible}
        >
          <div className="editorCards">
            <img alt="alt Text" src={activeGame.invitationCard} />
            <img alt="alt Text" src={activeGame.greetingCard} />
            <img alt="alt Text" src={activeGame.winnerCard} />
          </div>
          <Divider>Game Settings</Divider>
          <GameEditorForm
            activeGame={activeGame}
            updateEditedGame={updateEditedGame}
            closeGameEditor={closeGameEditor}
            gameNames={(games || [])
              .filter((game) => game._id !== activeGame._id)
              .map((game) => game.name)}
            gameIds={(games || [])
              .filter((game) => game._id !== activeGame._id)
              .map((game) => game.gid)}
          />
        </Drawer>
      )}

      {selectedGameId && (
        <Drawer
          width={550}
          title={`Game Id: ${selectedGameId}`}
          placement="right"
          onClose={closeGameCards}
          visible={isGameCardsVisible}
        >
          <CardsManager gameId={selectedGameId} />
        </Drawer>
      )}

      {selectedGameIdStats && (
        <Drawer
          width={550}
          title={(
            games.find((game) => game._id === selectedGameIdStats).name || ''
          ).substring(0, 30)}
          placement="right"
          onClose={closeGameStats}
          visible={isGameStatsVisible}
        >
          <GameStats gameId={selectedGameIdStats} />
        </Drawer>
      )}
    </>
  );
}

export default GamesManager;
