import React, { useState, useEffect } from 'react';

import {
  Drawer,
  notification,
  Table,
  Tag,
  Row,
  Col,
  Tree,
  Button,
  Input,
  Icon,
  Modal,
} from 'antd';

import { reqStyles, reqCardsCssClasses, deleteStyle } from '../../api';

import StyleEditorForm from './StyleEditorForm';

import './stylesManager.css';

const { TreeNode, DirectoryTree } = Tree;
const { Search } = Input;

function StylesManager() {
  const [styles, setStyles] = useState([]);
  const [usedStyles, setUsedStyles] = useState(null);

  const [isEditorVisible, setIsEditorVisible] = useState(false);
  const [activeStyleId, setActiveStyleId] = useState(null);
  const [searchName, setSearchName] = useState('');

  useEffect(() => {
    async function getStyles() {
      const { data: styles = [], status } = await reqStyles();
      const {
        data: cssClassesTypes = {},
        status: secondStatus,
      } = await reqCardsCssClasses();

      if (status === 200 && secondStatus === 200) {
        const stylesInfo = styles.map((style) => {
          switch (style.styleType) {
            case 'cssCard':
              return {
                ...style,
                key: style._id,
                used: Boolean(
                  cssClassesTypes.cardStyles &&
                    cssClassesTypes.cardStyles.includes(style.name)
                ),
              };
            case 'cssContent':
              return {
                ...style,
                key: style._id,
                used: Boolean(
                  cssClassesTypes.contentStyles &&
                    cssClassesTypes.contentStyles.includes(style.name)
                ),
              };
            case 'cssOptions':
              return {
                ...style,
                key: style._id,
                used: Boolean(
                  cssClassesTypes.optionsStyles &&
                    cssClassesTypes.optionsStyles.includes(style.name)
                ),
              };
            default:
              return {
                ...style,
                key: style._id,
                used: false,
              };
          }
        });
        setStyles(stylesInfo);
        setUsedStyles(cssClassesTypes);
      } else if (status === 401 || secondStatus === 401) {
        notification.warning({
          message: 'The authorization token is expired',
          description: 'Receive updated token and repeat the action.',
        });
      } else {
        notification.error({
          message: 'Server Error',
          description: 'Cannot process the operation.',
        });
      }
    }
    getStyles();
  }, []);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <a onClick={() => editStyle(record.key)}>{text}</a>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Type',
      dataIndex: 'styleType',
      className: 'centerPosition',
      key: 'styleType',
      render: (value) => {
        let color = '#2db7f5';
        if (value === 'cssContent') {
          color = '#87d068';
        }

        if (value === 'cssOptions') {
          color = '#108ee9';
        }

        return (
          <span>
            <Tag color={color}>{value.substring(3).toUpperCase()}</Tag>
          </span>
        );
      },
      filters: [
        { text: 'Card', value: 'cssCard' },
        { text: 'Content', value: 'cssContent' },
        { text: 'Options', value: 'cssOptions' },
      ],
      onFilter: (value, record) => record.styleType.includes(value),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      className: 'centerPosition',
      key: 'status',
      render: (value) => {
        const color = value === 'active' ? 'blue' : 'orange';
        return (
          <span>
            <Tag color={color}>{value}</Tag>
          </span>
        );
      },
      filters: [
        { text: 'active', value: 'active' },
        { text: 'disabled', value: 'disabled' },
      ],
      onFilter: (value, record) => record.status.includes(value),
    },
    {
      title: 'Used',
      dataIndex: 'used',
      className: 'centerPosition',
      key: 'used',
      render: (value) => {
        const color = value ? 'green' : 'lime';
        const text = value ? 'yes' : 'no';
        return (
          <span>
            <Tag color={color}>{text}</Tag>
          </span>
        );
      },
      filters: [
        { text: 'yes', value: true },
        { text: 'no', value: false },
      ],
      onFilter: (value, record) => record.used === value,
    },
    {
      title: 'Del',
      key: 'del',
      render: (record) => (
        <Icon
          onClick={() => deleteStyleConfirm(record.key, record.name)}
          type="delete"
        />
      ),
    },
  ];

  function editStyle(styleId) {
    setActiveStyleId(styleId);
    setIsEditorVisible(true);
  }

  function closeStyleEditor() {
    setActiveStyleId(null);
    setIsEditorVisible(false);
  }

  function updateStyle(newStyle) {
    const updatedStyles = styles.map((style) => {
      return style._id === newStyle._id
        ? {
            ...style,
            ...newStyle,
          }
        : style;
    });

    setStyles(updatedStyles);
  }

  function addStyle(newStyle) {
    const updatedStyles = [
      {
        ...newStyle,
        key: newStyle._id,
        used: false,
      },
      ...styles,
    ];

    setStyles(updatedStyles);
  }

  function deleteStyleConfirm(styleId, name) {
    Modal.confirm({
      title: 'Confirm',
      content: `Style ${name} will be removed form the collection. This operation does not modify cards styles.`,
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => removeStyle(styleId),
    });
  }

  async function removeStyle(styleId) {
    const { status } = await deleteStyle(styleId);

    if (status === 200) {
      const updateStyles = styles.filter((style) => style._id !== styleId);
      setStyles(updateStyles);
    } else if (status === 401) {
      notification.warning({
        message: 'The authorization token is expired',
        description: 'Receive updated token and repeat the action.',
      });
    } else {
      notification.error({
        message: 'Server Error',
        description: 'Cannot process the operation.',
      });
    }
  }

  const activeStyle = activeStyleId
    ? styles.find((style) => style._id === activeStyleId)
    : {
        _id: null,
        name: null,
        styleType: null,
        status: null,
      };

  return (
    <>
      <Row>
        <Col span={4}>
          {usedStyles && (
            <DirectoryTree selectable={false}>
              <TreeNode title="Card" key="card">
                {usedStyles.cardStyles &&
                  usedStyles.cardStyles.map((style) => (
                    <TreeNode title={style} key={style} isLeaf />
                  ))}
              </TreeNode>
              <TreeNode title="Content" key="content">
                {usedStyles.contentStyles &&
                  usedStyles.contentStyles.map((style) => (
                    <TreeNode title={style} key={style} isLeaf />
                  ))}
              </TreeNode>
              <TreeNode title="Options" key="options">
                {usedStyles.optionsStyles &&
                  usedStyles.optionsStyles.map((style) => (
                    <TreeNode title={style} key={style} isLeaf />
                  ))}
              </TreeNode>
            </DirectoryTree>
          )}
        </Col>
        <Col span={20}>
          <Row>
            <Col span={12}>
              <Search
                placeholder="Input style name"
                onSearch={(value) => setSearchName(value)}
                style={{ width: 270 }}
              />
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button
                onClick={() => editStyle(null)}
                type="primary"
                style={{ marginBottom: 16 }}
              >
                Add style to collection
              </Button>
            </Col>
          </Row>
          <Table
            dataSource={styles.filter((style) =>
              style.name.includes(searchName)
            )}
            columns={columns}
          />
        </Col>
      </Row>

      {isEditorVisible && (
        <Drawer
          width={550}
          title={
            activeStyleId ? `Style Id: ${activeStyle._id}` : 'Create new style'
          }
          placement="right"
          onClose={closeStyleEditor}
          visible={isEditorVisible}
        >
          <StyleEditorForm
            activeStyle={activeStyle}
            styleNames={(styles || [])
              .filter((styles) =>
                activeStyle._id ? styles._id !== activeStyle._id : true
              )
              .map((styles) => styles.name)}
            closeStyleEditor={closeStyleEditor}
            updateStyle={updateStyle}
            addStyle={addStyle}
          />
        </Drawer>
      )}
    </>
  );
}

export default StylesManager;
