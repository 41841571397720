import React, { useState, useEffect } from 'react';

import { reqGroups, reqGamesInfo } from '../../api';
import {
  notification,
  Row,
  Input,
  Button,
  Col,
  Drawer,
  List,
  Icon,
  Tag,
  Divider,
} from 'antd';

import GroupsEditorForm from './GroupsEditorForm';

const { Search } = Input;

function GroupsManager() {
  const newGroup = {
    _id: null,
    gid: null,
    name: null,
    description: null,
    games: [],
    status: 'disabled',
  };

  const [groups, setGroups] = useState([]);
  const [games, setGames] = useState([]);

  const [isEditorVisible, setIsEditorVisible] = useState(false);
  const [activeGroup, setActiveGroup] = useState(null);
  const [searchName, setSearchName] = useState('');

  useEffect(() => {
    async function getGroups() {
      const [groupData, gamesInfoData] = await Promise.all([
        reqGroups(),
        reqGamesInfo(),
      ]);

      if (groupData.status === 200 && gamesInfoData.status === 200) {
        const gamesInfo = (gamesInfoData.data || []).map((game) => ({
          id: game._id,
          gid: game.gid,
          name: game.name,
          status: game.status,
        }));
        setGroups(groupData.data || []);
        setGames(gamesInfo);
      } else if (
        groupData.status === 401 &&
        gamesInfoData.status === 401
      ) {
        notification.warning({
          message: 'The authorization token is expired',
          description: 'Receive updated token and repeat the action.',
        });
      } else {
        notification.error({
          message: 'Server Error',
          description: 'Cannot process the operation.',
        });
      }
    }
    getGroups();
  }, []);

  function editGroup(groupId) {
    const group = groupId
      ? groups.find((group) => group._id === groupId)
      : newGroup;

    setActiveGroup(group);
    setIsEditorVisible(true);
  }

  function closeGroupEditor() {
    setActiveGroup(newGroup);
    setIsEditorVisible(false);
  }

  function updateGroups(updatedGroup) {
    let isGroupNew = true;

    let updatedGroups = groups.map((group) => {
      if (group._id === updatedGroup._id) {
        isGroupNew = false;
        return updatedGroup;
      } else {
        return group;
      }
    });

    if (isGroupNew) {
      updatedGroups = [updatedGroup, ...groups];
    }

    setGroups(updatedGroups);
  }

  const listGames = (gamesIds) =>
    gamesIds.map((gameId) => {
      const game = games.find((game) => game.id === gameId);
      return (
        game &&
        game.id && (
          <Tag color="blue" key={game.id}>
            #{game.gid} {game.name}
          </Tag>
        )
      );
    });

  const IconText = ({ type, text }) => {
    if (text === 'active' || text === 'disabled') {
      return (
        <span>
          <Icon type={type} style={{ marginRight: 8 }} />
          <Tag color={text === 'active' ? 'green' : 'red'}>{text}</Tag>
        </span>
      );
    } else
      return (
        <span>
          <Icon type={type} style={{ marginRight: 8 }} />
          {text}
        </span>
      );
  };

  return (
    <>
      <Row>
        <Col span={16} offset={4}>
          <Row>
            <Col span={12}>
              <Search
                placeholder="Input group name"
                onSearch={(value) => setSearchName(value)}
                style={{ width: 270 }}
              />
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button
                onClick={() => editGroup(null)}
                type="primary"
                style={{ marginBottom: 16 }}
              >
                Add group to collection
              </Button>
            </Col>
          </Row>

          <Divider>Groups</Divider>

          <List
            itemLayout="vertical"
            size="large"
            dataSource={groups.filter((group) =>
              group.name.includes(searchName)
            )}
            renderItem={(item) => (
              <List.Item
                key={item._id}
                actions={[
                  <IconText type="unlock" text={item.status} key="unlock" />,
                ]}
              >
                <List.Item.Meta
                  title={
                    <a onClick={() => editGroup(item._id)}>#{item.gid} {item.name}</a>
                  }
                  description={item.description}
                />
                {listGames(item.games)}
              </List.Item>
            )}
          />
        </Col>
      </Row>

      {isEditorVisible && (
        <Drawer
          width={850}
          title={
            activeGroup && activeGroup._id
              ? `Group Id: ${activeGroup._id}`
              : 'Create new group'
          }
          placement="right"
          onClose={closeGroupEditor}
          visible={isEditorVisible}
        >
          <GroupsEditorForm
            activeGroup={activeGroup}
            closeGroupEditor={closeGroupEditor}
            updateGroups={updateGroups}
            games={games}
            groupIds={(groups || [])
              .filter((group) => group._id !== activeGroup._id)
              .map((group) => group.gid)}
            gamesDS={(games || [])
              .map((game) => {
                return {
                  key: game.id,
                  title: (game.status === 'open') ? `#${game.gid} ${game.name}` : `#${game.gid} ${game.name} <${game.status}>`,
                  color: (game.status !== 'open') ? 'red' : undefined,
                };
              })}
          />
        </Drawer>
      )}
    </>
  );
}

export default GroupsManager;
