import { authFetch } from './utils';

import config from '../config';

const { apiServer } = config;

export const reqGamesInfo = () =>
  authFetch(`${apiServer}/games/all`, {
    auth: true,
  });

export const reqGameCards = (gameId) =>
  authFetch(`${apiServer}/games/${gameId}/cards`, {
    auth: true,
  });

export const patchGame = (gameId, gameDto) =>
  authFetch(`${apiServer}/games/${gameId}`, {
    method: 'patch',
    data: gameDto,
    auth: true,
  });

export const deleteGame = (gameId) =>
  authFetch(`${apiServer}/games/${gameId}`, {
    method: 'delete',
    auth: true,
  });
