import React, { useState, useEffect } from 'react';

import {
  Drawer,
  notification,
  Table,
  Tag,
  Row,
  Col,
  Button,
  Input,
  Icon,
  Modal,
  Timeline,
} from 'antd';

import { reqProfiles, deleteProfile } from '../../api';

import ProfileEditorForm from './ProfileEditorForm';

import './profilesManager.css';

const { Search } = Input;

function ProfilesManager() {
  const [profiles, setProfiles] = useState([]);

  const [isEditorVisible, setIsEditorVisible] = useState(false);
  const [activeProfileId, setActiveProfileId] = useState(null);
  const [searchName, setSearchName] = useState('');

  useEffect(() => {
    async function getProfiles() {
      const { data: profiles = [], status } = await reqProfiles();

      if (status === 200) {
        setProfiles(profiles);
      } else if (status === 401) {
        notification.warning({
          message: 'The authorization token is expired',
          description: 'Receive updated token and repeat the action.',
        });
      } else {
        notification.error({
          message: 'Server Error',
          description: 'Cannot process the operation.',
        });
      }
    }
    getProfiles();
  }, []);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <a onClick={() => editProfile(record._id)}>{text}</a>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      className: 'centerPosition',
      key: 'status',
      render: (value) => {
        const color = value === 'active' ? 'blue' : 'orange';
        return (
          <span>
            <Tag color={color}>{value}</Tag>
          </span>
        );
      },
      filters: [
        { text: 'active', value: 'active' },
        { text: 'disabled', value: 'disabled' },
      ],
      onFilter: (value, record) => record.status.includes(value),
    },
    {
      title: 'Required Coins',
      dataIndex: 'requiredCoins',
      className: 'centerPosition',
      key: 'requiredCoins',
      render: (value) => value,
      sorter: (a, b) => a.requiredCoins - b.requiredCoins,
    },
    {
      title: 'Del',
      key: 'del',
      render: (record) => (
        <Icon
          onClick={() => deleteProfileConfirm(record.key, record.name)}
          type="delete"
        />
      ),
    },
  ];

  function editProfile(profileId) {
    setActiveProfileId(profileId);
    setIsEditorVisible(true);
  }

  function closeStyleEditor() {
    setActiveProfileId(null);
    setIsEditorVisible(false);
  }

  function updateProfile(newProfile) {
    const updatedProfile = profiles.map((profile) => {
      return profile._id === newProfile._id
        ? {
            ...profile,
            ...newProfile,
          }
        : profile;
    });

    setProfiles(updatedProfile);
  }

  function addProfile(newProfile) {
    const updatedProfile = [
      {
        ...newProfile,
        key: newProfile._id,
      },
      ...profiles,
    ];

    setProfiles(updatedProfile);
  }

  function deleteProfileConfirm(profileId, name) {
    Modal.confirm({
      title: 'Confirm',
      content: `Profile ${name} will be removed form the collection.`,
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => removeProfile(profileId),
    });
  }

  async function removeProfile(profileId) {
    const { status } = await deleteProfile(profileId);

    if (status === 200) {
      const updateProfiles = profiles.filter(
        (profile) => profile._id !== profileId
      );
      setProfiles(updateProfiles);
    } else if (status === 401) {
      notification.warning({
        message: 'The authorization token is expired',
        description: 'Receive updated token and repeat the action.',
      });
    } else {
      notification.error({
        message: 'Server Error',
        description: 'Cannot process the operation.',
      });
    }
  }

  function ShowProfiles({ profiles = [] }) {
    const sortedProfiles = profiles.length
      ? profiles.sort((a, b) => a.requiredCoins - b.requiredCoins)
      : [];

    const getColor = (profile) => {
      if (profile.requiredCoins === 0) return 'red';
      if (profile.status === 'disabled') return 'gray';
      return 'green';
    };

    return (
      <Timeline mode="alternate" reverse={true}>
        {sortedProfiles.map((profile, i) => (
          <Timeline.Item key={i} color={getColor(profile)}>
            <p>{profile.name}</p>
            <p>{profile.requiredCoins}</p>
          </Timeline.Item>
        ))}
      </Timeline>
    );
  }

  const activeProfile = activeProfileId
    ? profiles.find((profile) => profile._id === activeProfileId)
    : {
        _id: null,
        name: null,
        status: null,
        requiredCoins: 0,
      };

  return (
    <>
      <Row>
        <Col span={6}>
          <ShowProfiles profiles={profiles} />
        </Col>
        <Col span={18}>
          <Row>
            <Col span={12}>
              <Search
                placeholder="Input profile name"
                onSearch={(value) => setSearchName(value)}
                style={{ width: 270 }}
              />
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button
                onClick={() => editProfile(null)}
                type="primary"
                style={{ marginBottom: 16 }}
              >
                Add profile to collection
              </Button>
            </Col>
          </Row>
          <Table
            pagination={false}
            dataSource={profiles.filter((profile) =>
              profile.name.includes(searchName)
            )}
            columns={columns}
          />
        </Col>
      </Row>

      {isEditorVisible && (
        <Drawer
          width={550}
          title={
            activeProfileId
              ? `Profile Id: ${activeProfile._id}`
              : 'Create new profile'
          }
          placement="right"
          onClose={closeStyleEditor}
          visible={isEditorVisible}
        >
          <ProfileEditorForm
            activeProfile={activeProfile}
            profileNames={(profiles || [])
              .filter((profile) =>
                activeProfile._id ? profile._id !== activeProfile._id : true
              )
              .map((profile) => profile.name)}
            profileCoins={(profiles || [])
              .filter((profile) =>
                activeProfile._id ? profile._id !== activeProfile._id : true
              )
              .map((profile) => profile.requiredCoins)}
            closeStyleEditor={closeStyleEditor}
            updateProfile={updateProfile}
            addProfile={addProfile}
          />
        </Drawer>
      )}
    </>
  );
}

export default ProfilesManager;
