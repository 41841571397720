import { combineReducers } from 'redux';
import userInfo from './userInfo';
import userQuota from './userQuota';
import uploadCases from './uploadCases';
import gamesInfo from './gamesInfo';

export default combineReducers({
  gamesInfo,
  userInfo,
  uploadCases,
  userQuota
});
