import { authFetch } from './utils';

import config from '../config';

const { apiServer } = config;

export const reqProfiles = () =>
  authFetch(`${apiServer}/profiles`, {
    auth: true,
  });

export const postProfile = (profileDto) =>
  authFetch(`${apiServer}/profiles`, {
    method: 'post',
    data: profileDto,
    auth: true,
  });

export const patchProfile = (profileId, profileDto) =>
  authFetch(`${apiServer}/profiles`, {
    method: 'patch',
    params: { id: profileId },
    data: profileDto,
    auth: true,
  });

export const deleteProfile = (profileId) =>
  authFetch(`${apiServer}/profiles`, {
    method: 'delete',
    params: { id: profileId },
    auth: true,
  });
