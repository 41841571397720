import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Statistic, notification } from 'antd';

import { reqDashboardInfo } from '../../api';

import ActivitiesLineGraph from './ActivitiesLineGraph';

function GamesInfo({ games }) {
  return (
    <Card title='Games stored'>
      <Row gutter={16}>
        <Col span={8}>
          <Statistic title='Open' value={games.open || 0} />
        </Col>
        <Col span={8}>
          <Statistic title='Hidden' value={games.hidden || 0} />
        </Col>
        <Col span={8}>
          <Statistic title='Closed' value={games.closed || 0} />
        </Col>
      </Row>
    </Card>
  );
}

function UsersInfo({ users }) {
  return (
    <Card title='Users authorized'>
      <Row gutter={16}>
        <Col span={12}>
          <Statistic title='Facebook' value={users.facebook || 0} />
        </Col>
        <Col span={12}>
          <Statistic title='Google' value={users.google || 0} />
        </Col>
      </Row>
    </Card>
  );
}

function SessionsInfo({ sessions }) {
  return (
    <Card title='Sessions initiated'>
      <Row gutter={16}>
        <Col span={12}>
          <Statistic title='Authorized' value={sessions.authorized || 0} />
        </Col>
        <Col span={12}>
          <Statistic title='Anonymous' value={sessions.anonymous || 0} />
        </Col>
      </Row>
    </Card>
  );
}

function SessionStatuses({ sessionStatuses }) {
  return (
    <Card title='Games played'>
      <Row gutter={16}>
        <Col span={8}>
          <Statistic title='Started' value={sessionStatuses.started || 0} />
        </Col>
        <Col span={8}>
          <Statistic title='Finished' value={sessionStatuses.finished || 0} />
        </Col>
        <Col span={8}>
          <Statistic title='Failed' value={sessionStatuses.failed || 0} />
        </Col>
      </Row>
    </Card>
  );
}

function Dashboard() {
  const [dashboardInfo, setDashboardInfo] = useState({});

  useEffect(() => {
    async function getDashboardInfo() {
      const { data, status } = await reqDashboardInfo();

      if (status === 200) {
        setDashboardInfo(data);
      } else if (status === 401) {
        notification.warning({
          message: 'The authorization token is expired',
          description: 'Receive updated token and repeat the action.',
        });
      } else {
        notification.error({
          message: 'Server Error',
          description: 'Cannot process the operation.',
        });
      }
    }

    getDashboardInfo();
  }, []);

  return (
    <div style={{ padding: '20px' }}>
      <Row gutter={16}>
        <Col span={6}>
          {dashboardInfo.sessionStatuses && (
            <SessionStatuses sessionStatuses={dashboardInfo.sessionStatuses} />
          )}
        </Col>
        <Col span={6}>
          {dashboardInfo.games && <GamesInfo games={dashboardInfo.games} />}
        </Col>
        <Col span={6}>
          {dashboardInfo.users && <UsersInfo users={dashboardInfo.users} />}
        </Col>
        <Col span={6}>
          {dashboardInfo.sessions && (
            <SessionsInfo sessions={dashboardInfo.sessions} />
          )}
        </Col>
      </Row>
      <ActivitiesLineGraph />
    </div>
  );
}

export default Dashboard;
