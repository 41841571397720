import React, { useRef, useEffect, useState } from 'react';
import Chart from 'chart.js';
import { notification, DatePicker } from 'antd';

import moment from 'moment';

import { reqDashboardActivities } from '../../api';

const { RangePicker } = DatePicker;

function ActivitiesLineGraph() {
  const chartRef = useRef(null);
  const [chart, setChart] = useState(null);

  useEffect(() => {
    const graphRef = chartRef.current.getContext('2d');

    const lineChart = new Chart(graphRef, {
      type: 'bar',
      data: {
        datasets: [
          {
            label: 'Sessions',
            backgroundColor: 'rgba(255,221,50,0.2)',
            borderColor: 'rgba(255,221,50,0.5)',
            borderWidth: 1,
            data: [],
            yAxisID: 'Session',
          },
          {
            label: 'Users',
            backgroundColor: 'rgba(193,46,12,0.2)',
            borderColor: 'rgba(193,46,12,0.5)',
            borderWidth: 1,
            data: [],
            yAxisID: 'Users',
          },
        ],
      },
      options: {
        title: {
          display: true,
          text: 'Games sessions & New users',
        },
        scales: {
          yAxes: [
            {
              id: 'Session',
              position: 'right',
              type: 'linear',
              ticks: {
                min: 0,
                stepSize: 1,
              },
            },
            {
              id: 'Users',
              position: 'left',
              type: 'linear',
              ticks: {
                min: 0,
                stepSize: 1,
              },
            },
          ],
          xAxes: [
            {
              type: 'time',
              time: {
                unit: 'day',
              },
            },
          ],
        },
      },
    });

    setChart(lineChart);
  }, []);

  async function onChange(dates, dateStrings) {
    const { data, status } = await reqDashboardActivities(
      dateStrings[0],
      dateStrings[1]
    );

    if (status === 200) {
      const sessions = (data.sessions || []).map((s) => ({
        x: `${s._id.year}-${s._id.month}-${s._id.day}`,
        y: s.count,
      }));

      const users = (data.users || []).map((s) => ({
        x: `${s._id.year}-${s._id.month}-${s._id.day}`,
        y: s.count,
      }));

      chart.data.datasets[0].data = sessions || [];
      chart.data.datasets[1].data = users || [];

      chart.update();
    } else if (status === 401) {
      notification.warning({
        message: 'The authorization token is expired',
        description: 'Receive updated token and repeat the action.',
      });
    } else {
      notification.error({
        message: 'Server Error',
        description: 'Cannot process the operation.',
      });
    }
  }

  return (
    <div>
      <div style={{ padding: '20px', textAlign: 'center' }}>
        <RangePicker
          allowClear={false}
          ranges={{
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'This Week': [moment().startOf('week'), moment().endOf('week')],
          }}
          onChange={onChange}
        />
      </div>
      <canvas id='graph' ref={chartRef} />
    </div>
  );
}

export default ActivitiesLineGraph;
