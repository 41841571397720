import React, { useState } from 'react';

import { Form, Input, Select, Button, notification } from 'antd';

import { patchStyle, postStyle } from '../../api';

const { Option } = Select;

function StyleEditorForm(props) {
  const { getFieldDecorator, validateFields } = props.form;
  const {
    activeStyle,
    styleNames,
    closeStyleEditor,
    updateStyle,
    addStyle,
  } = props;

  const [processStyleUpdate, setProcessStyleUpdate] = useState(false);

  async function editStyle(e) {
    e.preventDefault();

    await validateFields(async (err, values) => {
      if (!err) {
        setProcessStyleUpdate(true);

        const { _id: styleId = null } = activeStyle;
        const { data: newStyle, status } = styleId
          ? await patchStyle(styleId, values)
          : await postStyle(values);

        if (status === 200 || status === 201) {
          if (styleId && status === 200) {
            updateStyle({ _id: styleId, ...values });
          } else if (status === 201) {
            addStyle(newStyle);
          }
        } else if (status === 401) {
          notification.warning({
            message: 'The authorization token is expired',
            description: 'Receive updated token and repeat the action.',
          });
        } else {
          notification.error({
            message: 'Server Error',
            description: 'Cannot process the operation.',
          });
        }

        setProcessStyleUpdate(false);
        closeStyleEditor();
      }
    });
  }

  function validateToExistedStyleName(rule, value, callback) {
    if (styleNames.includes(value)) {
      callback('The name is already used.');
    } else {
      callback();
    }
  }

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  const styleTypes = [
    <Option key='cssCard'>
      Card :: The style will be applied to the game card.
    </Option>,
    <Option key='cssContent'>
      Content :: The style for the content of the card.
    </Option>,
    <Option key='cssOptions'>
      Options :: The style for the card options part.
    </Option>,
  ];

  const styleStatuses = [
    <Option key='active'>
      Active :: Will be processed during games upload.
    </Option>,
    <Option key='disabled'>
      Disabled :: Will be restricted during games upload..
    </Option>,
  ];

  return (
    <Form {...formItemLayout} onSubmit={editStyle}>
      <Form.Item label='Name' hasFeedback>
        {getFieldDecorator('name', {
          initialValue: activeStyle.name,
          rules: [
            { required: true, message: 'Style name is required' },
            {
              validator: validateToExistedStyleName,
            },
          ],
        })(<Input placeholder='Style name' />)}
      </Form.Item>

      <Form.Item label='Description'>
        {getFieldDecorator('description', {
          initialValue: activeStyle.description,
          rules: [{ required: true, message: 'Style description is required' }],
        })(<Input.TextArea rows={4} placeholder='Style description' />)}
      </Form.Item>

      <Form.Item label='Type'>
        {getFieldDecorator('styleType', {
          initialValue: activeStyle.styleType,
          rules: [{ required: true, message: 'Please set the style type' }],
        })(
          <Select style={{ width: '100%' }} placeholder='Select the style type'>
            {styleTypes}
          </Select>
        )}
      </Form.Item>

      <Form.Item label='Status'>
        {getFieldDecorator('status', {
          initialValue: activeStyle.status,
          rules: [{ required: true, message: 'Please set the style status' }],
        })(
          <Select style={{ width: '100%' }} placeholder='Select style status'>
            {styleStatuses}
          </Select>
        )}
      </Form.Item>

      <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
        <Button type='primary' htmlType='submit' loading={processStyleUpdate}>
          Update
        </Button>
      </Form.Item>
    </Form>
  );
}

export default Form.create({ name: 'styleEditorForm' })(StyleEditorForm);
