import React, { useState } from 'react';

import {
  Form,
  Input,
  Select,
  Button,
  notification,
  InputNumber,
  Upload,
  Icon,
} from 'antd';

import config from '../../config';

import { patchPartnership, postPartnership } from '../../api';

const { Option } = Select;

function PartnershipEditorForm(props) {
  const { getFieldDecorator, validateFields } = props.form;
  const {
    activePartnership,
    closePartnershipEditor,
    updatePartnerships,
    games,
  } = props;

  const [processPartnershipUpdate, setProcessPartnershipUpdate] = useState(
    false
  );

  const uploadProps = {
    name: 'file',
    accept: 'image/svg+xml',
    listType: 'picture',
    multiple: false,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    action: config.fileUploadUrl,
    onChange(info) {
      const { status } = info.file;

      if (status === 'done') {
        notification.success({
          message: 'Logo uploaded is completed.',
          description: `${info.file.name} has been uploaded.`,
        });
      } else if (status === 'error') {
        if (info.file.error && info.file.error.status === 401) {
          notification.info({
            message: 'The access token is expired.',
            description: 'Login with admin credentials.',
          });
        }

        notification.error({
          message: 'Upload failed.',
          description: `${info.file.name} was not uploaded.`,
        });
      }
    },
  };

  function validURL(str) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  }

  function validateReceiverURL(rule, value, callback) {
    if (!validURL(value)) {
      callback('Is not valid URL');
    } else {
      callback();
    }
  }

  const getS3FileLocation = (e) => {
    if (e && e.file && e.file.status === 'done') {
      return e.file.response;
    }

    return null;
  };

  async function editPartnership(e) {
    e.preventDefault();

    await validateFields(async (err, values) => {
      if (!err) {
        setProcessPartnershipUpdate(true);

        const { _id: partnershipId = null } = activePartnership;
        const { data: partnership, status } = partnershipId
          ? await patchPartnership(partnershipId, values)
          : await postPartnership(values);

        if (status === 200 || status === 201) {
          updatePartnerships(
            partnershipId ? { _id: partnershipId, ...values } : partnership
          );
        } else if (status === 401) {
          notification.warning({
            message: 'The authorization token is expired',
            description: 'Receive updated token and repeat the action.',
          });
        } else {
          notification.error({
            message: 'Server Error',
            description: 'Cannot process the operation.',
          });
        }

        setProcessPartnershipUpdate(false);
        closePartnershipEditor();
      }
    });
  }

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const profileStatuses = [
    <Option key="active">Active :: Will be used for the users bonuses.</Option>,
    <Option key="disabled">
      Disabled :: Will not be used for the users rewards.
    </Option>,
  ];

  return (
    <Form {...formItemLayout} onSubmit={editPartnership}>
      <Form.Item label="Name">
        {getFieldDecorator('name', {
          initialValue: activePartnership.name,
          rules: [{ required: true, message: 'Partnership name is required' }],
        })(<Input placeholder="Partnership name" />)}
      </Form.Item>

      <Form.Item label="Upload Logo">
        {getFieldDecorator('logoURL', {
          valuePropName: 'logoURL',
          getValueFromEvent: getS3FileLocation,
          initialValue: activePartnership.logoURL,
          rules: [{ required: true, message: 'Partnership logo is required' }],
        })(
          <Upload {...uploadProps}>
            <Button>
              <Icon type="upload" /> Select SVG Partnership Logo
            </Button>
          </Upload>
        )}
      </Form.Item>

      <Form.Item label="Description">
        {getFieldDecorator('description', {
          initialValue: activePartnership.description,
          rules: [
            { required: true, message: 'Partnership description is required' },
          ],
        })(<Input.TextArea rows={4} placeholder="Partnership description" />)}
      </Form.Item>

      <Form.Item label="Receiver URL" hasFeedback>
        {getFieldDecorator('receiverURL', {
          initialValue: activePartnership.receiverURL,
          rules: [
            { required: true, message: 'Partnership Receiver URL is required' },
            { validator: validateReceiverURL },
          ],
        })(<Input placeholder="Partnership Receiver URL" />)}
      </Form.Item>

      <Form.Item label="Collected Coins">
        {getFieldDecorator('coinsCollected', {
          initialValue: activePartnership.coinsCollected,
          rules: [
            {
              required: true,
              message: 'Partnership collected coins is required',
            },
          ],
        })(
          <InputNumber
            min={0}
            style={{ width: '100%' }}
            placeholder="Haw many coins user has to collect"
          />
        )}
      </Form.Item>

      <Form.Item label="Coins Cost">
        {getFieldDecorator('coinsCost', {
          initialValue: activePartnership.coinsCost,
          rules: [
            {
              required: true,
              message: 'Partnership coins cost is required',
            },
          ],
        })(
          <InputNumber
            min={0}
            style={{ width: '100%' }}
            placeholder="Coins cost for the bonus"
          />
        )}
      </Form.Item>

      <Form.Item label="Required Games">
        {getFieldDecorator('requiredGames', {
          initialValue: activePartnership.requiredGames,
          rules: [
            { required: true, message: 'Select required games', type: 'array' },
          ],
        })(
          <Select mode="multiple" placeholder="Required games">
            {games.map((game) => (
              <Option key={game.id} value={game.id}>
                {game.name}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>

      <Form.Item label="Status">
        {getFieldDecorator('status', {
          initialValue: activePartnership.status,
          rules: [{ required: true, message: 'Please set the profile status' }],
        })(
          <Select style={{ width: '100%' }} placeholder="Select profile status">
            {profileStatuses}
          </Select>
        )}
      </Form.Item>

      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <Button
          type="primary"
          htmlType="submit"
          loading={processPartnershipUpdate}
        >
          Update
        </Button>
      </Form.Item>
    </Form>
  );
}

export default Form.create({ name: 'PartnershipEditorForm' })(
  PartnershipEditorForm
);
