import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Statistic, notification } from 'antd';
import Chart from 'chart.js';

import { reqGameStat } from '../../api';

function FrequencyBarGraph({ sessionsFrequency }) {
  const chartRef = useRef(null);
  const dataSet = sessionsFrequency.map((s) => ({
    y: `${s._id.year}-${s._id.month}-01`,
    x: s.count,
  }));

  let firstMonth = null;
  let lastMonth = null;

  try {
    firstMonth = sessionsFrequency[0];
    lastMonth = sessionsFrequency[sessionsFrequency.length - 1];

    firstMonth = firstMonth
      ? new Date(firstMonth._id.year, firstMonth._id.month)
      : new Date(2020, 4);
    lastMonth = lastMonth
      ? new Date(lastMonth._id.year, lastMonth._id.month)
      : firstMonth;
  } catch (e) {
    console.warn('Wrong data set');
  }

  const monthDiff = (d1, d2) => {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth() + 1;
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  };

  const nMonths =
    firstMonth && lastMonth ? monthDiff(firstMonth, lastMonth) : 0;

  useEffect(() => {
    const graphRef = chartRef.current.getContext('2d');
    graphRef.canvas.parentNode.style.height = `${(nMonths + 3) * 40 + 20}px`;
    new Chart(graphRef, {
      type: 'horizontalBar',
      data: {
        datasets: [
          {
            label: 'Sessions',
            barPercentage: 0.7,
            maxBarThickness: 10,
            backgroundColor: 'rgba(193,46,12,0.2)',
            borderColor: 'rgba(193,46,12,0.5)',
            borderWidth: 1,
            data: dataSet,
          },
        ],
      },
      options: {
        title: {
          display: true,
          text: 'Game sessions per month',
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                min: 0,
                stepSize: 1,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              type: 'time',
              time: {
                unit: 'month',
              },
            },
          ],
        },
      },
    });
  }, [sessionsFrequency, nMonths, dataSet]);

  return (
    <div>
      <canvas id='graph' ref={chartRef} />
    </div>
  );
}

function GameStats(props) {
  const { gameId } = props;

  const [gameStats, setGameStats] = useState({});

  useEffect(() => {
    async function getGameStats(gameId) {
      const { data, status } = await reqGameStat(gameId);

      if (status === 200) {
        setGameStats(data);
      } else if (status === 401) {
        notification.warning({
          message: 'The authorization token is expired',
          description: 'Receive updated token and repeat the action.',
        });
      } else {
        notification.error({
          message: 'Server Error',
          description: 'Cannot process the operation.',
        });
      }
    }

    getGameStats(gameId);
  }, [gameId]);

  return (
    <div style={{ padding: '10px' }}>
      <Row gutter={16}>
        {(gameStats.sessionTypes || []).map((st) => (
          <Col span={8} key={st._id}>
            <Statistic title={st._id.toUpperCase()} value={st.sessions} />
          </Col>
        ))}
      </Row>
      <FrequencyBarGraph
        sessionsFrequency={gameStats.sessionsFrequency || []}
      />
    </div>
  );
}

export default GameStats;
