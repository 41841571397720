const API_VERSION = 'api/v1';
const GAME_UPLOAD_ROUTE = `${API_VERSION}/games/upload`;
const FILE_UPLOAD_ROUTE = `${API_VERSION}/uploads/file`;

const config = {
  apiServer: `${process.env.REACT_APP_DEV_API_HOST}/${API_VERSION}`,
  gameUploadUrl: `${process.env.REACT_APP_DEV_API_HOST}/${GAME_UPLOAD_ROUTE}`,
  fileUploadUrl: `${process.env.REACT_APP_DEV_API_HOST}/${FILE_UPLOAD_ROUTE}`,
};

if (process.env.NODE_ENV === 'production') {
  config.apiServer = `${process.env.REACT_APP_PROD_API_HOST}/${API_VERSION}`;
  config.gameUploadUrl = `${process.env.REACT_APP_PROD_API_HOST}/${GAME_UPLOAD_ROUTE}`;
  config.fileUploadUrl = `${process.env.REACT_APP_PROD_API_HOST}/${FILE_UPLOAD_ROUTE}`;
}

export default config;
