import axios from 'axios';

async function authFetch(url, options) {
  try {
    const {
      method = 'get',
      data = {},
      params = {},
      headers = {},
      auth = true
    } = options;

    const config = {
      url,
      method,
      data,
      params
    };

    if (auth) {
      const token = localStorage.getItem('token');

      if (!token) {
        return (window.location.reload());
      }

      config.headers = {
        ...headers,
        Authorization: `Bearer ${token}`
      };
    }

    const response = await axios(config);

    return response;
  } catch (error) {
    return error.response;
  }
}

export default authFetch;
