import { authFetch } from './utils';

import config from '../config';

const { apiServer } = config;

export const reqPartnerships = () =>
  authFetch(`${apiServer}/partnerships`, {
    auth: true,
  });

export const postPartnership = (partnershipDto) =>
  authFetch(`${apiServer}/partnerships`, {
    method: 'post',
    data: partnershipDto,
    auth: true,
  });

export const patchPartnership = (partnershipId, partnershipDto) =>
  authFetch(`${apiServer}/partnerships`, {
    method: 'patch',
    params: { id: partnershipId },
    data: partnershipDto,
    auth: true,
  });

export const deletePartnership = (partnershipId) =>
  authFetch(`${apiServer}/partnerships`, {
    method: 'delete',
    params: { id: partnershipId },
    auth: true,
  });
