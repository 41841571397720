import { authFetch } from './utils';

import config from '../config';

const { apiServer } = config;

export const reqAdminToken = password =>
  authFetch(`${apiServer}/users/admin-login`, {
    auth: false,
    method: 'post',
    data: { password }
  });
