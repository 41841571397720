import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { Layout } from 'antd';

import Routs from '../../Routs';
import Navigation from './Navigation';

import './skeleton.css';

function Skeleton() {
  return (
    <Layout>
      <Router>
        <Layout>
          <Navigation />
          <Layout>
            <Routs />
          </Layout>
        </Layout>
      </Router>
    </Layout>
  );
}

export default Skeleton;
