import React, { useState } from 'react';

import { Form, Input, Select, Button, notification, InputNumber } from 'antd';

import { patchProfile, postProfile } from '../../api';

const { Option } = Select;

function ProfileEditorForm(props) {
  const { getFieldDecorator, validateFields } = props.form;
  const {
    activeProfile,
    profileNames,
    profileCoins,
    closeStyleEditor,
    updateProfile,
    addProfile,
  } = props;

  const [processProfileUpdate, setProcessProfileUpdate] = useState(false);

  async function editProfile(e) {
    e.preventDefault();

    await validateFields(async (err, values) => {
      if (!err) {
        setProcessProfileUpdate(true);

        const { _id: profileId = null } = activeProfile;
        const { data: newProfile, status } = profileId
          ? await patchProfile(profileId, values)
          : await postProfile(values);

        if (status === 200 || status === 201) {
          if (profileId && status === 200) {
            updateProfile({ _id: profileId, ...values });
          } else if (status === 201) {
            addProfile(newProfile);
          }
        } else if (status === 401) {
          notification.warning({
            message: 'The authorization token is expired',
            description: 'Receive updated token and repeat the action.',
          });
        } else {
          notification.error({
            message: 'Server Error',
            description: 'Cannot process the operation.',
          });
        }

        setProcessProfileUpdate(false);
        closeStyleEditor();
      }
    });
  }

  function validateToExistedProfileName(rule, value, callback) {
    if (profileNames.includes(value)) {
      callback('The name is already used.');
    } else {
      callback();
    }
  }

  function validateToExistedProfileCoin(rule, value, callback) {
    if (profileCoins.includes(value)) {
      callback('This coin limit is already used.');
    } else {
      callback();
    }
  }

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const profileStatuses = [
    <Option key="active">
      Active :: Will be used when user complete the game.
    </Option>,
    <Option key="disabled">
      Disabled :: Will not be used after user reward.
    </Option>,
  ];

  return (
    <Form {...formItemLayout} onSubmit={editProfile}>
      <Form.Item label="Name" hasFeedback>
        {getFieldDecorator('name', {
          initialValue: activeProfile.name,
          rules: [
            { required: true, message: 'Profile name is required' },
            {
              validator: validateToExistedProfileName,
            },
          ],
        })(<Input placeholder="Profile name" />)}
      </Form.Item>

      <Form.Item label="Description">
        {getFieldDecorator('description', {
          initialValue: activeProfile.description,
          rules: [
            { required: true, message: 'Profile description is required' },
          ],
        })(<Input.TextArea rows={4} placeholder="Profile description" />)}
      </Form.Item>

      <Form.Item label="Required Coins">
        {getFieldDecorator('requiredCoins', {
          initialValue: activeProfile.requiredCoins,
          rules: [
            {
              required: true,
              message: 'Profile minimum required coins is required',
            },
            { validator: validateToExistedProfileCoin },
          ],
        })(
          <InputNumber
            min={0}
            style={{ width: '100%' }}
            placeholder="Profile minimum required coins"
          />
        )}
      </Form.Item>

      <Form.Item label="Status">
        {getFieldDecorator('status', {
          initialValue: activeProfile.status,
          rules: [{ required: true, message: 'Please set the profile status' }],
        })(
          <Select style={{ width: '100%' }} placeholder="Select profile status">
            {profileStatuses}
          </Select>
        )}
      </Form.Item>

      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <Button type="primary" htmlType="submit" loading={processProfileUpdate}>
          Update
        </Button>
      </Form.Item>
    </Form>
  );
}

export default Form.create({ name: 'ProfileEditorForm' })(ProfileEditorForm);
