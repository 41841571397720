import React, { useState } from 'react';

import { Upload, Icon, List, Avatar, notification } from 'antd';

import config from '../../config';

function OperationLog(props) {
  const { uploadLog = [] } = props;

  return (
    <List
      itemLayout='horizontal'
      dataSource={uploadLog}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={
              <Avatar
                size='small'
                style={
                  item.done
                    ? { color: 'green', backgroundColor: 'white' }
                    : { color: 'red', backgroundColor: 'white' }
                }
                icon={
                  item.done ? (
                    <Icon type='check-circle' />
                  ) : (
                    <Icon type='close-circle' />
                  )
                }
              />
            }
            title={item.action}
          />
        </List.Item>
      )}
    />
  );
}

function UploadGames() {
  const [uploadLog, setUploadLog] = useState([]);

  const uploadAssetsProps = {
    name: 'game',
    accept: 'application/zip',
    multiple: false,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    action: config.gameUploadUrl,
    onChange(info) {
      const { status } = info.file;

      if (status === 'done') {
        notification.success({
          message: 'Uploaded is completed.',
          description: `${info.file.name} has been uploaded.`,
        });

        const { operationLog = [] } = info.file.response;

        const { done = false } = operationLog[operationLog.length - 1];

        if (!done) {
          notification.warning({
            message: 'The config file is incorrect',
            description: `${info.file.name} has was skipped`,
          });
        }

        setUploadLog(info.file.response.operationLog);
      } else if (status === 'error') {
        if (info.file.error && info.file.error.status === 401) {
          notification.info({
            message: 'The access token is expired.',
            description: 'Login with admin credentials.',
          });
        }

        notification.error({
          message: 'Upload failed.',
          description: `${info.file.name} was not uploaded.`,
        });
      }
    },
  };

  return (
    <>
      <Upload.Dragger {...uploadAssetsProps}>
        <p className='ant-upload-drag-icon'>
          <Icon type='inbox' />
        </p>
        <p className='ant-upload-text'>
          Drop your file here or simply click to upload
        </p>
        <p className='ant-upload-hint'>
          You can upload one zip file with game content
        </p>
      </Upload.Dragger>

      <OperationLog uploadLog={uploadLog} />
    </>
  );
}

export default UploadGames;
