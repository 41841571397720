import * as React from "react";
import classNames from "classnames";
import PureRenderMixin from "rc-util/lib/PureRenderMixin";
import Lazyload from "react-lazy-load";

import {
  Checkbox,
  Icon,
} from 'antd';

export default class ListItem extends React.Component {
  shouldComponentUpdate(...args) {
    return PureRenderMixin.shouldComponentUpdate.apply(this, args);
  }

  render() {
    const {
      renderedText,
      renderedEl,
      item,
      lazy,
      checked,
      disabled,
      prefixCls,
      onClick,
      onMoveUp,
      onMoveDown,
    } = this.props;

    const className = classNames({
      [`${prefixCls}-content-item`]: true,
      [`${prefixCls}-content-item-disabled`]: disabled || item.disabled,
    });

    let title;
    if (typeof renderedText === "string" || typeof renderedText === "number") {
      title = String(renderedText);
    }

    let moveUpButton;
    if (onMoveUp) {
      moveUpButton = (
        <Icon
          onClick={() => onMoveUp(item)}
          type="up-circle"
          style={{ marginLeft: 5 }}
          theme="twoTone"
        />
      );
    }

    let moveDownButton;
    if (onMoveDown) {
      moveDownButton = (
        <Icon
          onClick={() => onMoveDown(item)}
          type="down-circle"
          style={{ marginLeft: 5 }}
          theme="twoTone"
        />
      );
    }

    const listItem = (
      <li
        className={className}
        title={title}
      >
        <Checkbox
          checked={checked}
          disabled={disabled || item.disabled}
          onClick={disabled || item.disabled ? undefined : () => onClick(item)}
        />
        {moveUpButton}
        {moveDownButton}
        <span
          className={`${prefixCls}-content-item-text`}
          style={{ color: item.color }}
        >
          {renderedEl}
        </span>
      </li>
    );
    let children = null;
    if (lazy) {
      const lazyProps = {
        height: 32,
        offset: 500,
        throttle: 0,
        debounce: false,
        ...lazy,
      };
      children = <Lazyload {...lazyProps}>{listItem}</Lazyload>;
    } else {
      children = listItem;
    }

    return children;
  }
}
