import React from 'react';
import { Link } from 'react-router-dom';

import { Layout, Menu, Icon } from 'antd';

const { Sider } = Layout;

function Navigation() {
  return (
    <Sider width={200} style={{ background: '#fff' }}>
      <img
        className="logo"
        alt="EMA Logo"
        src={process.env.PUBLIC_URL + '/logo.svg'}
      />
      <Menu mode="inline" defaultSelectedKeys={['manage-games']}>
        <Menu.Item key="manage-games">
          <Link to="/">
            <Icon type="setting" />
            <span className="nav-text">Manage Games</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="manage-groups">
          <Link to="/manage-groups">
            <Icon type="deployment-unit" />
            <span className="nav-text">Manage Game Groups</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="manage-styles">
          <Link to="/manage-styles">
            <Icon type="layout" />
            <span className="nav-text">Manage Styles</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="manage-profiles">
          <Link to="/manage-profiles">
            <Icon type="user" />
            <span className="nav-text">Manage Profiles</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="manage-partnerships">
          <Link to="/manage-partnerships">
            <Icon type="deployment-unit" />
            <span className="nav-text">Manage Partnerships</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="upload-games">
          <Link to="/upload-games">
            <Icon type="cloud-upload" />
            <span className="nav-text">Upload Game</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="dashboard">
          <Link to="/dashboard">
            <Icon type="bar-chart" />
            <span className="nav-text">Statistics</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="login">
          <Link to="/sign-in">
            <Icon type="key" />
            <span className="nav-text">Log ( In / Out )</span>
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
}

export default Navigation;
