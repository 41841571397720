import { authFetch } from './utils';

import config from '../config';

const { apiServer } = config;

export const reqGroups = () =>
  authFetch(`${apiServer}/groups`, {
    auth: true,
  });

export const postGroup = (groupDto) =>
  authFetch(`${apiServer}/groups`, {
    method: 'post',
    data: groupDto,
    auth: true,
  });

export const patchGroup = (groupId, groupDto) =>
  authFetch(`${apiServer}/groups`, {
    method: 'patch',
    params: { id: groupId },
    data: groupDto,
    auth: true,
  });

export const deleteGroup = (groupId) =>
  authFetch(`${apiServer}/groups`, {
    method: 'delete',
    params: { id: groupId },
    auth: true,
  });
