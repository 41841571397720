import React, { useState } from 'react';

import {
  Form,
  Input,
  Select,
  Button,
  notification,
  InputNumber,
} from 'antd';

import { patchGroup, postGroup } from '../../api';

import Transfer from '../transfer';

const { Option } = Select;

function GroupEditorForm(props) {
  const { getFieldDecorator, validateFields } = props.form;
  const {
    activeGroup,
    groupIds,
    closeGroupEditor,
    updateGroups,
    gamesDS,
  } = props;

  const [processGroupUpdate, setProcessGroupUpdate] = useState(
    false
  );

  async function editGroup(e) {
    e.preventDefault();

    await validateFields(async (err, values) => {
      if (!err) {
        setProcessGroupUpdate(true);

        const { _id: groupId = null } = activeGroup;
        const { data: group, status } = groupId
          ? await patchGroup(groupId, values)
          : await postGroup(values);

        if (status === 200 || status === 201) {
          updateGroups(
            groupId ? { _id: groupId, ...values } : group
          );
        } else if (status === 401) {
          notification.warning({
            message: 'The authorization token is expired',
            description: 'Receive updated token and repeat the action.',
          });
        } else {
          notification.error({
            message: 'Server Error',
            description: 'Cannot process the operation.',
          });
        }

        setProcessGroupUpdate(false);
        closeGroupEditor();
      }
    });
  }

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  function validateToExistedGroupId(rule, value, callback) {
    if (groupIds.includes(value)) {
      callback('The group id is already used.');
    } else {
      callback();
    }
  }

  const profileStatuses = [
    <Option key="active">
      Active :: Will be used.
    </Option>,
    <Option key="disabled">
      Disabled :: Will not be used.
    </Option>,
  ];

  function handleChangeGroupGames(targetKeys: string[], direction: string, moveKeys: any) {
    if (direction === 'right') {
      activeGroup.games.push(...moveKeys);
    } else if (direction === 'left') {
      activeGroup.games = targetKeys;
    }
  }

  return (
    <Form {...formItemLayout} onSubmit={editGroup}>

      <Form.Item label="Group id">
        {getFieldDecorator('gid', {
          initialValue: activeGroup.gid,
          rules: [
            {
              required: true,
              message: 'Group id is required',
            },
            {
              validator: validateToExistedGroupId,
            },
          ],
        })(
          <InputNumber
            min={0}
            max={9999}
            style={{ width: '100%' }}
            placeholder="Group id, 0 - default group"
          />
        )}
      </Form.Item>

      <Form.Item label="Name">
        {getFieldDecorator('name', {
          initialValue: activeGroup.name,
          rules: [{ required: true, message: 'Group name is required' }],
        })(<Input placeholder="Group name" />)}
      </Form.Item>

      <Form.Item label="Description">
        {getFieldDecorator('description', {
          initialValue: activeGroup.description,
          rules: [
            { required: true, message: 'Group description is required' },
          ],
        })(<Input.TextArea rows={4} placeholder="Group description" />)}
      </Form.Item>

      <Form.Item label="Status">
        {getFieldDecorator('status', {
          initialValue: activeGroup.status,
          rules: [{ required: true, message: 'Please set the group status' }],
        })(
          <Select style={{ width: '100%' }} placeholder="Select group status">
            {profileStatuses}
          </Select>
        )}
      </Form.Item>

      <Form.Item label="Games">
        {getFieldDecorator('games', {
          initialValue: activeGroup.games,
          rules: [
            { required: true, message: 'Please choose games' },
          ],
        })(
          <Transfer
            listStyle={{ width: 275, height: 300 }}
            dataSource={gamesDS}
            targetKeys={activeGroup.games}
            titles={['Available', 'Used']}
            onChange={handleChangeGroupGames}
            render={item => item.title}
            showSearch={false}
          />
        )}
      </Form.Item>

      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <Button
          type="primary"
          htmlType="submit"
          loading={processGroupUpdate}
        >
          Update
        </Button>
      </Form.Item>
    </Form>
  );
}

export default Form.create({ name: 'GroupEditorForm' })(
  GroupEditorForm
);
