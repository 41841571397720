import { authFetch } from './utils';

import config from '../config';

const { apiServer } = config;

export const reqDashboardInfo = () =>
  authFetch(`${apiServer}/statistics/dashboard`, {
    auth: true,
  });

export const reqDashboardActivities = (from, to) =>
  authFetch(`${apiServer}/statistics/dashboard/activity/${from}/${to}`, {
    auth: true,
  });

export const reqGameStat = (gameId) =>
  authFetch(`${apiServer}/statistics/game/${gameId}`, {
    auth: true,
  });
