import React, { useState, useEffect } from 'react';

import { reqPartnerships, reqGamesInfo } from '../../api';
import {
  notification,
  Row,
  Input,
  Button,
  Col,
  Drawer,
  List,
  Icon,
  Tag,
  Divider,
} from 'antd';

import PartnershipsEditorForm from './PartnershipsEditorForm';

const { Search } = Input;

function PartnershipsManager() {
  const newPartnership = {
    _id: null,
    name: null,
    logoUrl: null,
    description: null,
    receiverURL: null,
    coinsCollected: null,
    coinsCost: null,
    requiredGames: [],
    status: 'disabled',
  };

  const [partnerships, setPartnerships] = useState([]);
  const [games, setGames] = useState([]);

  const [isEditorVisible, setIsEditorVisible] = useState(false);
  const [activePartnership, setActivePartnership] = useState(null);
  const [searchName, setSearchName] = useState('');

  useEffect(() => {
    async function getPartnerships() {
      const [partnershipData, gamesInfoData] = await Promise.all([
        reqPartnerships(),
        reqGamesInfo(),
      ]);

      if (partnershipData.status === 200 && gamesInfoData.status === 200) {
        const gamesInfo = (gamesInfoData.data || []).map((game) => ({
          id: game._id,
          name: game.name,
        }));
        setPartnerships(partnershipData.data || []);
        setGames(gamesInfo);
      } else if (
        partnershipData.status === 401 &&
        gamesInfoData.status === 401
      ) {
        notification.warning({
          message: 'The authorization token is expired',
          description: 'Receive updated token and repeat the action.',
        });
      } else {
        notification.error({
          message: 'Server Error',
          description: 'Cannot process the operation.',
        });
      }
    }
    getPartnerships();
  }, []);

  function editPartnership(partnershipId) {
    const partnership = partnershipId
      ? partnerships.find((partnership) => partnership._id === partnershipId)
      : newPartnership;

    setActivePartnership(partnership);
    setIsEditorVisible(true);
  }

  function closePartnershipEditor() {
    setActivePartnership(newPartnership);
    setIsEditorVisible(false);
  }

  function updatePartnerships(updatedPartnership) {
    let isPartnershipNew = true;

    let updatedPartnerships = partnerships.map((partnership) => {
      if (partnership._id === updatedPartnership._id) {
        isPartnershipNew = false;
        return updatedPartnership;
      } else {
        return partnership;
      }
    });

    if (isPartnershipNew) {
      updatedPartnerships = [updatedPartnership, ...partnerships];
    }

    setPartnerships(updatedPartnerships);
  }

  const listRequiredGames = (requiredGamesIds) =>
    requiredGamesIds.map((gameId) => {
      const game = games.find((game) => game.id === gameId);
      return (
        game &&
        game.id && (
          <Tag color="blue" key={game.id}>
            {game.name}
          </Tag>
        )
      );
    });

  const IconText = ({ type, text }) => {
    if (text === 'active' || text === 'disabled') {
      return (
        <span>
          <Icon type={type} style={{ marginRight: 8 }} />
          <Tag color={text === 'active' ? 'green' : 'red'}>{text}</Tag>
        </span>
      );
    } else
      return (
        <span>
          <Icon type={type} style={{ marginRight: 8 }} />
          {text}
        </span>
      );
  };

  return (
    <>
      <Row>
        <Col span={16} offset={4}>
          <Row>
            <Col span={12}>
              <Search
                placeholder="Input partner name"
                onSearch={(value) => setSearchName(value)}
                style={{ width: 270 }}
              />
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button
                onClick={() => editPartnership(null)}
                type="primary"
                style={{ marginBottom: 16 }}
              >
                Add partnership to collection
              </Button>
            </Col>
          </Row>

          <Divider>Partnerships</Divider>

          <List
            itemLayout="vertical"
            size="large"
            dataSource={partnerships.filter((partnership) =>
              partnership.name.includes(searchName)
            )}
            renderItem={(item) => (
              <List.Item
                key={item._id}
                actions={[
                  <IconText
                    type="crown"
                    text={item.coinsCollected}
                    key="coinsCollected"
                  />,
                  <IconText type="dollar" text={item.coinsCost} key="dollar" />,
                  <IconText type="unlock" text={item.status} key="unlock" />,
                  <IconText type="api" text={item.receiverURL} key="api" />,
                ]}
                extra={
                  <img width={100} alt="partnership logo" src={item.logoURL} />
                }
              >
                <List.Item.Meta
                  title={
                    <a onClick={() => editPartnership(item._id)}>{item.name}</a>
                  }
                  description={item.description}
                />
                {listRequiredGames(item.requiredGames)}
              </List.Item>
            )}
          />
        </Col>
      </Row>

      {isEditorVisible && (
        <Drawer
          width={550}
          title={
            activePartnership && activePartnership._id
              ? `Partnership Id: ${activePartnership._id}`
              : 'Create new partner'
          }
          placement="right"
          onClose={closePartnershipEditor}
          visible={isEditorVisible}
        >
          <PartnershipsEditorForm
            activePartnership={activePartnership}
            closePartnershipEditor={closePartnershipEditor}
            updatePartnerships={updatePartnerships}
            games={games}
          />
        </Drawer>
      )}
    </>
  );
}

export default PartnershipsManager;
