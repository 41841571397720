import React, { useEffect, useState } from 'react';
import { Icon, notification, Select, Table, Divider, Tag } from 'antd';

import { reqGameCards, patchCard, reqStyles } from '../../api';

import './cardsManager.css';

const { Option } = Select;

function CardOptions({ options, playCard }) {
  const optionsIcon = (iconType) => {
    switch (iconType) {
      case 'right':
        return (
          <Icon
            style={{ color: '#2666E1', marginRight: 10 }}
            type="check-circle"
          />
        );
      case 'wrong':
        return (
          <Icon
            style={{ color: '#E12626', marginRight: 10 }}
            type="close-circle"
          />
        );
      case 'win':
        return (
          <Icon
            style={{ color: '#7926E1', marginRight: 10 }}
            type="issues-close"
          />
        );
      case 'lost':
        return (
          <Icon style={{ color: '#000000', marginRight: 10 }} type="stop" />
        );
      default:
        return null;
    }
  };

  return (
    <ul>
      {options.map((option) =>
        option.contentType === 'text' ? (
          <li key={option._id} onClick={() => playCard(option.cardId)}>
            {optionsIcon(option.status)} {option.caption}
          </li>
        ) : (
          <li key={option._id} onClick={() => playCard(option.cardId)}>
            {optionsIcon(option.status)}
            <img height="30" alt="Game cad caption pic" src={option.svgPic} />
          </li>
        )
      )}
    </ul>
  );
}

function CardTemplate({ card, playCard, cssTypes }) {
  const { cardStyles = [], contentStyles = [], optionsStyles = [] } = cssTypes;

  const updateCardStyle = (value) => {
    patchCard(card._id, {
      cssType: 'cssCard',
      cssName: value,
    });
  };

  const updateContentStyle = (value) => {
    patchCard(card._id, {
      cssType: 'cssContent',
      cssName: value,
    });
  };

  const updateOptionsStyle = (value) => {
    patchCard(card._id, {
      cssType: 'cssOptions',
      cssName: value,
    });
  };

  const styleOptions = (style) => (
    <Option
      key={style.name}
      value={style.name}
      disabled={style.status === 'disabled' ? true : false}
    >
      {style.name}
    </Option>
  );

  return (
    <div className="cardWrapper">
      <div className="cardTemplate">
        <div className="cssClass">
          <Select
            showSearch
            onChange={updateCardStyle}
            defaultValue={card.cssCard}
            style={{ width: '100%' }}
            placeholder="Select card css class name"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {cardStyles.map((style) => styleOptions(style))}
          </Select>
        </div>
        <div className="captionTemplate">
          <div className="cssClass">
            <Select
              showSearch
              onChange={updateContentStyle}
              defaultValue={card.cssContent}
              style={{ width: '100%' }}
              placeholder="Select content css class name"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {contentStyles.map((style) => styleOptions(style))}
            </Select>
          </div>
          {card.contentType === 'text' &&
            card.caption.map((c, i) => <p key={i}>{c}</p>)}
          {card.contentType === 'pic' && (
            <img height="185" alt="Game cad caption pic" src={card.svgPic} />
          )}
          {card.contentType === 'video' && (
            <iframe
              height="185"
              src={card.videoUrl}
              frameborder="0"
              allowfullscreen
              showinfo="0"
              controls="0"
            ></iframe>
          )}
          {(card.externalLink || []).length ? (
            <div className="externalLinks">
              <ul>
                {(card.externalLink || []).map((link, i) => (
                  <li key={`link-${i}`}>
                    <a
                      href={
                        link.options ? link.url.concat(link.options) : link.url
                      }
                    >
                      {link.caption}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
        <div className="actionInfo">{card.actionInfo}</div>
        <div className="actionNotes">
          {(card.actionNotes || []).map((note, i) => (
            <p key={i}>{note}</p>
          ))}
        </div>
        <div className="optionsTemplate">
          <div className="cssClass">
            <Select
              showSearch
              onChange={updateOptionsStyle}
              defaultValue={card.cssOptions}
              style={{ width: '100%' }}
              placeholder="Select options css class name"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {optionsStyles.map((style) => styleOptions(style))}
            </Select>
          </div>
          <CardOptions options={card.options} playCard={playCard} />
        </div>
      </div>
    </div>
  );
}

function CardsTable({ gameCards = [], setVisibleCad }) {
  const columns = [
    {
      title: 'Card Id',
      dataIndex: '_id',
      key: '_id',
      render: (value) => (
        <a
          role="button"
          onClick={() =>
            setVisibleCad(gameCards.find((card) => card._id === value))
          }
        >
          {value}
        </a>
      ),
    },
    {
      title: 'Content',
      dataIndex: 'contentType',
      key: 'contentType',
      render: (value) => (
        <span>
          <Tag color="blue">{value}</Tag>
        </span>
      ),
      filters: [
        { text: 'Text', value: 'text' },
        { text: 'Picture', value: 'pic' },
        { text: 'Video', value: 'video' },
      ],
      onFilter: (value, record) => record.contentType.includes(value),
    },
    {
      title: 'Type',
      dataIndex: 'cardType',
      key: 'cardType',
      render: (value) => {
        let color = 'geekblue';
        if (value === 'first') {
          color = 'volcano';
        }

        if (value === 'last') {
          color = 'green';
        }

        return (
          <span>
            <Tag color={color}>{value.toUpperCase()}</Tag>
          </span>
        );
      },
      filters: [
        { text: 'First', value: 'first' },
        { text: 'Last', value: 'last' },
        { text: 'General', value: 'general' },
        { text: 'Important', value: 'important' },
        { text: 'Advertise', value: 'advertise' },
        { text: 'Vip', value: 'vip' },
        { text: 'Sensitive', value: 'sensitive' },
      ],
      onFilter: (value, record) => record.cardType.includes(value),
    },
    {
      title: 'Opt.',
      dataIndex: 'options',
      key: 'options',
      render: (value) => value.length,
      sorter: (a, b) => a.options.length - b.options.length,
      sortDirections: ['descend', 'ascend'],
    },
  ];

  const dataSource = gameCards.map((card) => ({
    ...card,
    key: card._id,
  }));

  return <Table dataSource={dataSource} columns={columns} />;
}

function CardsManager(props) {
  const { gameId } = props;
  const [gameCards, setGameCards] = useState([]);
  const [visibleCard, setVisibleCad] = useState(null);
  const [cssTypes, setCssTypes] = useState({});

  useEffect(() => {
    async function getGameCards(gameId) {
      const { data: cards = [], status } = await reqGameCards(gameId);

      if (status === 200) {
        setGameCards(cards);
        setVisibleCad(cards.find((card) => card.cardType === 'first'));
      } else if (status === 401) {
        notification.warning({
          message: 'The authorization token is expired',
          description: 'Receive updated token and repeat the action.',
        });
      } else {
        notification.error({
          message: 'Server Error',
          description: 'Cannot process the operation.',
        });
      }

      const { data: cssClassesTypes = [] } = await reqStyles();

      const getStylesByType = (styleType) =>
        cssClassesTypes
          .filter((style) => style.styleType === styleType)
          .map((style) => ({ name: style.name, status: style.status }));

      const stylesOptions = {
        cardStyles: getStylesByType('cssCard'),
        contentStyles: getStylesByType('cssContent'),
        optionsStyles: getStylesByType('cssOptions'),
      };

      setCssTypes(stylesOptions);
    }

    getGameCards(gameId);
  }, [gameId]);

  function playCard(cardId) {
    setVisibleCad(gameCards.find((c) => c._id === cardId));
  }

  return (
    <>
      {visibleCard && (
        <CardTemplate
          card={visibleCard}
          playCard={playCard}
          cssTypes={cssTypes}
        />
      )}
      <Divider>Game Cards</Divider>
      <CardsTable gameCards={gameCards} setVisibleCad={setVisibleCad} />
    </>
  );
}

export default CardsManager;
