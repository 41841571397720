import { authFetch } from './utils';

import config from '../config';

const { apiServer } = config;

export const reqStyles = () =>
  authFetch(`${apiServer}/styles`, {
    auth: true,
  });

export const postStyle = (styleDto) =>
  authFetch(`${apiServer}/styles`, {
    method: 'post',
    data: styleDto,
    auth: true,
  });

export const patchStyle = (styleId, styleDto) =>
  authFetch(`${apiServer}/styles/${styleId}`, {
    method: 'patch',
    data: styleDto,
    auth: true,
  });

export const deleteStyle = (styleId) =>
  authFetch(`${apiServer}/styles/${styleId}`, {
    method: 'delete',
    auth: true,
  });
