import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';

import Dashboard from './components/dashboard/Dashboard';
import GamesManager from './components/gamesManager/GamesManager';
import UploadGames from './components/uploadGames/UploadGames';
import StylesManager from './components/stylesManager/StylesManager';
import ProfilesManager from './components/profilesManager/ProfilesManager';
import SignIn from './components/signIn/SignIn';
import PartnershipsManager from './components/partnershipsManager/PartnershipsManager';
import GroupsManager from './components/groupsManager/GroupsManager';

const { Content } = Layout;

function Routs() {
  const AuthenticatedRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem('token') ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/sign-in' }} />
        )
      }
    />
  );

  return (
    <Content
      style={{
        background: '#fff',
        padding: 24,
        margin: 0,
        minHeight: 280,
      }}
    >
      <Route path="/sign-in"
        component={SignIn}
        exact
      />
      <AuthenticatedRoute
        path="/"
        component={GamesManager}
        exact
      />
      <AuthenticatedRoute
        path="/manage-groups"
        component={GroupsManager}
        exact
      />
      <AuthenticatedRoute
        path="/dashboard"
        component={Dashboard}
        exact
      />
      <AuthenticatedRoute
        path="/upload-games"
        component={UploadGames}
        exact
      />
      <AuthenticatedRoute
        path="/manage-styles"
        component={StylesManager}
        exact
      />
      <AuthenticatedRoute
        path="/manage-profiles"
        component={ProfilesManager}
        exact
      />
      <AuthenticatedRoute
        path="/manage-partnerships"
        component={PartnershipsManager}
        exact
      />
      <AuthenticatedRoute
        path="/user"
        component={Dashboard}
        exact
      />
    </Content>
  );
}

export default Routs;
