import { gamesInfo } from '../actions/actionTypes';

const initialState = {
  loaded: false,
  uploadLog: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case gamesInfo.set:
      return { ...state, games: action.games, loaded: true };
    case gamesInfo.add:
      return {
        ...state,
        games: [...state.games, action.game],
        uploadLog: action.uploadLog
      };
    case gamesInfo.update:
      return {
        ...state,
        games: state.games.map(game =>
          game._id === action.game._id ? action.game : game
        ),
        uploadLog: action.uploadLog
      };
    default:
      return state;
  }
};
