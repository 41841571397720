import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Card, Modal } from 'antd';

function GameCard(props) {
  const { game, editGame, showGameCards, showGameStats, removeGame } = props;

  function deleteStyleConfirm(gameId, name) {
    Modal.confirm({
      title: 'Confirm',
      content: `The game: < ${name} > will be removed with all its cards `,
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => removeGame(gameId),
    });
  }

  return (
    <Card
      hoverable
      style={{ width: 240 }}
      cover={
        <img
          onClick={() => showGameCards(game._id)}
          alt="Game greeting Card"
          src={game.status === 'open' ? game.greetingCard : game.invitationCard}
        />
      }
      actions={[
        <Icon
          type="bar-chart"
          key="bar-chart"
          onClick={() => showGameStats(game._id)}
        />,
        <Icon type="edit" key="edit" onClick={() => editGame(game)} />,
        <Icon
          type="delete"
          key="delete"
          onClick={() => deleteStyleConfirm(game._id, game.name)}
        />,
      ]}
    >
      <Card.Meta
        title={
          game.name.length > 33 ? `${game.name.substring(0, 30)}...` : game.name
        }
        description={
          game.description.length > 25
            ? `${game.description.substring(0, 23)}...`
            : game.description
        }
      />
    </Card>
  );
}

GameCard.propTypes = {
  game: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    invitationCard: PropTypes.string.isRequired,
    greetingCard: PropTypes.string.isRequired,
  }),
  editGame: PropTypes.func.isRequired,
  showGameCards: PropTypes.func.isRequired,
};

export default GameCard;
