import { authFetch } from './utils';

import config from '../config';

const { apiServer } = config;

export const patchCard = (cardId, cssDto) =>
  authFetch(`${apiServer}/cards/options/css/${cardId}`, {
    method: 'patch',
    data: cssDto,
    auth: true
  });

export const reqCardsCssClasses = () =>
  authFetch(`${apiServer}/cards/options/css`, {
    auth: true
  });
