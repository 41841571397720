import React, { useState } from 'react';

import { Form, Input, Select, Button, notification, Slider, Rate, InputNumber } from 'antd';

import { patchGame } from '../../api';

const { Option } = Select;

function GameEditorForm(props) {
  const {
    activeGame,
    gameIds,
    gameNames,
    updateEditedGame,
    closeGameEditor
  } = props;

  const { getFieldDecorator, validateFields } = props.form;

  const [processGameUpdate, setProcessGameUpdate] = useState(false);

  async function editGame(e) {
    e.preventDefault();

    await validateFields(async (err, values) => {
      if (!err) {
        setProcessGameUpdate(true);

        const gameDto = {
          invitationCard: activeGame.invitationCard,
          greetingCard: activeGame.greetingCard,
          winnerCard: activeGame.winnerCard,
          ...values,
        };

        const { data, status } = await patchGame(activeGame._id, gameDto);
        if (status === 200) {
          updateEditedGame(data);
        } else if (status === 401) {
          notification.warning({
            message: 'The authorization token is expired',
            description: 'Receive updated token and repeat the action.',
          });
        } else {
          notification.error({
            message: 'Server Error',
            description: 'Cannot process the operation.',
          });
        }

        setProcessGameUpdate(false);
        closeGameEditor();
      }
    });
  }

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  function validateToExistedGamesName(rule, value, callback) {
    if (gameNames.includes(value)) {
      callback('The name is already used.');
    } else {
      callback();
    }
  }

  function validateToExistedGameId(rule, value, callback) {
    if (gameIds.includes(value)) {
      callback('The game id is already used.');
    } else {
      callback();
    }
  }

  const gameStatuses = [
    <Option key="open">Open :: The game works and is in public access.</Option>,
    <Option key="hidden">
      Hidden :: The game is testing, not in public access.
    </Option>,
    <Option key="closed">
      Closed :: The game was removed from public access.
    </Option>,
  ];

  const gameDifficulties = [
    <Option key="easy">
      Easy :: Introduction to basic rules of security.
    </Option>,
    <Option key="normal">
      Normal :: General cases for wide range of users.
    </Option>,
    <Option key="hard">
      Hard :: Nontrivial decisions of security policies.
    </Option>,
  ];

  return (
    <Form {...formItemLayout} onSubmit={editGame}>

      <Form.Item label="Game id">
        {getFieldDecorator('gid', {
          initialValue: activeGame.gid,
          rules: [
            {
              required: true,
              message: 'Game id is required',
            },
            {
              validator: validateToExistedGameId,
            },
          ],
        })(
          <InputNumber
            min={1}
            max={9999}
            style={{ width: '100%' }}
            placeholder="Game id"
          />
        )}
      </Form.Item>

      <Form.Item label="Name" hasFeedback>
        {getFieldDecorator('name', {
          initialValue: activeGame.name,
          rules: [
            { required: true, message: 'Game name is required' },
            {
              validator: validateToExistedGamesName,
            },
          ],
        })(<Input placeholder="Game name" />)}
      </Form.Item>

      <Form.Item label="Notes">
        {getFieldDecorator('description', {
          initialValue: activeGame.description,
          rules: [{ required: true, message: 'Game description is required' }],
        })(<Input.TextArea rows={4} placeholder="Game description" />)}
      </Form.Item>

      <Form.Item label="Reward">
        {getFieldDecorator('reward', {
          initialValue: activeGame.reward,
        })(
          <Slider
            min={0}
            max={1000}
            step={50}
            marks={{
              0: '0',
              200: '200 Coins',
              500: '500',
              1000: '1000',
            }}
          />
        )}
      </Form.Item>

      <Form.Item label="Status">
        {getFieldDecorator('status', {
          initialValue: activeGame.status,
          rules: [{ required: true, message: 'Please set the game status' }],
        })(
          <Select
            style={{ width: '100%' }}
            placeholder="Select the game status"
          >
            {gameStatuses}
          </Select>
        )}
      </Form.Item>

      <Form.Item label="Difficulty">
        {getFieldDecorator('difficulty', {
          initialValue: activeGame.difficulty,
          rules: [
            { required: true, message: 'Please set the game difficulty' },
          ],
        })(
          <Select
            style={{ width: '100%' }}
            placeholder="Select the game difficulty"
          >
            {gameDifficulties}
          </Select>
        )}
      </Form.Item>

      <Form.Item label="Rating">
        {getFieldDecorator('rating', {
          initialValue: activeGame.rating ? activeGame.rating : 0,
        })(<Rate />)}
      </Form.Item>

      <Form.Item label="Duration">
        {getFieldDecorator('timeframe', {
          initialValue: activeGame.timeframe,
        })(
          <Slider
            min={5}
            max={30}
            step={1}
            marks={{
              5: '5',
              10: '10 minutes',
              20: '20',
              30: '30',
            }}
          />
        )}
      </Form.Item>

      <Form.Item label="Tags">
        {getFieldDecorator('tags', {
          initialValue: activeGame.tags,
        })(<Input placeholder="Set game tags for SEO performance" />)}
      </Form.Item>

      <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
        <Button type="primary" htmlType="submit" loading={processGameUpdate}>
          Update
        </Button>
      </Form.Item>
    </Form>
  );
}
export default Form.create({ name: 'gameEditorForm' })(GameEditorForm);
