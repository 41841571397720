import React from 'react';
import { Form, Icon, Input, Button, Typography, notification } from 'antd';

import { reqAdminToken } from '../../api';

import './signIn.css';

const { Text } = Typography;

function LogInForm(props) {
  const { getFieldDecorator } = props.form;
  localStorage.removeItem('token');

  const processResponse = (res) => {
    if (res.status === 200) {
      localStorage.setItem('token', res.data.bearerToken);
      return (window.location.reload());
    } else {
      notification.error({
        message: 'Password is not valid',
        description: 'Check the password and try again.',
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        const { password } = values;
        reqAdminToken(password).then(processResponse);
      }
    });
  };

  return (
    <div className="signIn">
      <Form onSubmit={handleSubmit} className="signIn-form">
        <div className="signIn-note">
          <Text strong className="signIn-note">
            ACCOUNT LOGIN
          </Text>
        </div>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="signIn-form-button"
          >
            SIGN IN
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

const SignIn = Form.create({ name: 'logInForm' })(LogInForm);

export default SignIn;
