import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './redux/store';

import Skeleton from './components/skeleton/Skeleton';
import SignIn from './components/signIn/SignIn';

ReactDOM.render(
  <Provider store={configureStore()}>
    {!localStorage.getItem('token') ? <SignIn /> : <Skeleton />}
  </Provider>,
  document.getElementById('root')
);
